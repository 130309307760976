<template>
  <AppWidgetBlock
    v-bind="{config}"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <span class="chart-title">Session By Device</span>
    <div class="flex-grow-1 d-flex align-center justify-center my-2">
      <pie-chart
        :height="160"
        :chartdata="chartData"
        :options="options"
      />
    </div>
    <div
      v-for="(item, index) in topItems"
      :key="index"
      class="d-flex flex-wrap"
      :class="{'mb-2': index != topItems.length - 1}"
    >
      <div class="d-flex flex-fill align-center mx-2">
        <v-icon :color="colors[index]" size="14" left>
          {{ item.icon }}
        </v-icon>

        <div class="text-capitalize legend-text">
          {{ item.domain }}
        </div>
      </div>
      <div class="d-flex flex-fill align-center justify-end total-number">
        {{ getNumberFormat(item.total) }} ({{ ((100 * item.total) / allTotals).toFixed() }}%)
      </div>
    </div>
  </AppWidgetBlock>
</template>

<script>
import PieChart from "@/sharedComponents/charts/PieChart";
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import _ from "lodash";

export default {
  name: "PieChartWidget",
  components: {
    PieChart,
    AppWidgetBlock,
  },
  props: {
    config: {
      default: () => ({
        size: '1x2',
        name: 'Session By Device'
      })
    },
    data: {
      type: Array,
      required: true,
    }
  },
  data(){
    return {
      options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const index = tooltipItem.index;
              
              return data.labels[index] + ": " + new Intl.NumberFormat().format(data.datasets[0].data[index])
            },
          },
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false
          }],
        }
      },
      colors: [
        '#4682e4',
        '#eb716d',
        '#92d7d5',
        '#e3a24a',
      ],
    }
  },
  computed: {
    chartData() {
      return {
        datasets: [
          {
            datalabels: {
              display: false,
            },
            backgroundColor: ["#4682e4","#eb716d","#92d7d5","#e3a24a"],
            borderWidth: 0,
            data: this.topItems.map(item => item.total),
          },
        ],
        labels: this.topItems.map(item => _.startCase(item.domain)),
      }
    },
    topItems() {
      return this.data.map(item => {
        let icon;
        if (item.dimension.deviceCategory == "desktop") {
          icon = "mdi-monitor"
        }
        if (item.dimension.deviceCategory == "mobile") {
          icon = "mdi-cellphone"
        }
        if (item.dimension.deviceCategory == "tablet") {
          icon = "mdi-tablet"
        }

        return {
          total: parseInt(item.metric.sessions),
          domain: item.dimension.deviceCategory,
          icon,
        }
      })
    },
    allTotals() {
      return this.data.reduce((partialSum, a) => partialSum + parseInt(a.metric.sessions), 0)
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    getNumberFormat(stat) {
      return new Intl.NumberFormat().format(stat)
    }
  },
};
</script>

<style lang="scss" scoped>
.total-number {
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #66788e;
}

.chart-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
</style>
