<template>
  <app-widget-block
    v-bind="{ config }"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="d-flex">
      <div class="side-bar">
        <v-btn
          v-for="item in items"
          :key="item.label"
          block
          depressed
          text
          class="justify-space-between side-bar-item"
          height="58"
          :class="{'v-btn--has-bg primary--text': selectedDataType == item.value}"
          @click="selectedDataType = item.value"
        >
          <span class="text-left">
            <div class="text-none stat">{{ item.stat }}</div>
            <div class="text-none caption">{{ item.label }}</div>
          </span>
          <span class="text-right">
            <v-icon size="12" class="right">
              $chevron_down_thin
            </v-icon>
          </span>
        </v-btn>
      </div>

      <v-divider vertical inset class="mx-4" />

      <div class="chart-container">
        <div class="chart-title mb-1">
          Engagements Over Time
        </div>
        <line-chart
          :height="250"
          :chartdata="chartData"
          :options="options" 
        />
      </div>
    </div>
  </app-widget-block>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import LineChart from "@/sharedComponents/charts/LineChart";

export default {
  name: "LineChartWidget",
  components: {
    LineChart,
    AppWidgetBlock,
  },
  props: {
    config: {
      default: () => ({
        size: "2x1",
        name: "Banner Statistics",
      }),
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDataType: "visitors",
    };
  },
  computed: {
    options() {
      const stepSize = Math.max(...(this.selectedData?.data ?? [])) / 4;
      const showPercentage = this.selectedDataType === 'bounce_rates';
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              if (showPercentage) {
                return parseFloat(tooltipItem.value).toFixed(2) + '%';
              }

              return new Intl.NumberFormat().format(tooltipItem.value)
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize,
                callback: function(value) {
                  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(value);
                }
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      }
    },
    items() {
      const avgSessionDuration = this.data?.total?.avgTimeOnSite?.[0]?.metric?.avgSessionDuration;
      const bounceRate = this.data?.total?.bounceRate?.[0]?.metric?.bounceRate;
      const totalVisitorsUser = this.data?.total?.totalVisitors?.[0]?.metric?.users;
      const totalSession = this.data?.total?.totalSessions?.[0]?.metric?.sessions
      return [
        {
          stat: totalVisitorsUser ? new Intl.NumberFormat().format(totalVisitorsUser) : 0,
          label: "Visitors",
          value: "visitors",
        },
        {
          stat: totalSession ? new Intl.NumberFormat().format(totalSession) : 0,
          label: "Sessions",
          value: "sessions",
        },
        {
          stat: bounceRate ? parseFloat(bounceRate).toFixed(2) + "%" : 0,
          label: "Bounce Rate",
          value: "bounce_rates",
        },
        {
          stat: avgSessionDuration ? parseFloat(avgSessionDuration).toFixed(2) + " sec" : 0,
          label: "Time on Site",
          value: "duration",
        },
      ]
    },
    selectedData() {
      if (this.selectedDataType === "sessions") {
        return this.sessionData;
      }
      if (this.selectedDataType === "bounce_rates") {
        return this.bounceRateData;
      }
      if (this.selectedDataType === "visitors") {
        return this.userData;
      }
      if (this.selectedDataType === "duration") {
        return this.durationData;
      }

      return this.sessionData;
    },
    userData() {
      return {
        data: this.data.overTime?.users?.map(session => session.metric.users),
        label: this.data.overTime?.users?.map(session => {
          const year = session.dimension.date.slice(0, 4);
          const month = parseInt(session.dimension.date.slice(4, 6)) - 1;
          const date = session.dimension.date.slice(6, 8);
          const d = new Date(year, month, date)
          return d.toLocaleString('default', { month: 'short' }) + " " + d.getDate();
        })
      };
    },
    durationData() {
      return {
        data: this.data.overTime?.durations?.map(session => session.metric.sessionDuration),
        label: this.data.overTime?.durations?.map(session => {
          const year = session.dimension.date.slice(0, 4);
          const month = parseInt(session.dimension.date.slice(4, 6)) - 1;
          const date = session.dimension.date.slice(6, 8);
          const d = new Date(year, month, date)
          return d.toLocaleString('default', { month: 'short' }) + " " + d.getDate();
        })
      };
    },
    sessionData() {
      return {
        data: this.data.overTime?.sessions?.map(session => session.metric.sessions),
        label: this.data.overTime?.sessions?.map(session => {
          const year = session.dimension.date.slice(0, 4);
          const month = parseInt(session.dimension.date.slice(4, 6)) - 1;
          const date = session.dimension.date.slice(6, 8);
          const d = new Date(year, month, date)
          return d.toLocaleString('default', { month: 'short' }) + " " + d.getDate();
        })
      };
    },
    bounceRateData() {
      return {
        data: this.data.overTime?.bounce_rate?.map(session => session.metric.bounceRate),
        label: this.data.overTime?.bounce_rate?.map(session => {
          const year = session.dimension.date.slice(0, 4);
          const month = parseInt(session.dimension.date.slice(4, 6)) - 1;
          const date = session.dimension.date.slice(6, 8);
          const d = new Date(year, month, date)
          return d.toLocaleString('default', { month: 'short' }) + " " + d.getDate();
        })
      };
    },
    chartData() {
      return {
        datasets: [
          {
            data: this.selectedData?.data ?? [],
            datalabels: {
              display: false,
            },
            backgroundColor: "rgba(43, 132, 235, 0.05)",
            borderColor: "#2b84eb",
            pointBackgroundColor: "#2b84eb",
            borderWidth: 2,
            pointBorderColor: "white",
            pointBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            pointHoverBorderColor: "white",
            pointHoverBorderWidth: 2,
            pointHoverRadius: 4,
          },
        ],
        labels: this.selectedData?.label ?? [],
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.side-bar {
  width: 200px;
}

.right {
  transform: rotate(-90deg);
}

.down {
  transform: rotate(90deg);
}

.chart-title {
  font-size: 15px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.side-bar-item:not(.primary--text) .caption {
  color: #66788e !important;
}

.caption {
  font-weight: normal !important;
  font-size: 12px !important;
  font-family: "Open Sans", sans-serif !important;
}

.stat {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
}

.chart-container {
  width: 100%;
}
</style>
