<template>
  <app-widget-block
    v-bind="{ config }"
    class="container"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      class="flex-grow-1 flex-shrink-0"
    >
      <template #top>
        <span class="chart-title">Top Pages Viewed</span>
      </template>
    </v-data-table>
  </app-widget-block>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";

export default {
  name: "TopPageViewWidget",
  components: {
    AppWidgetBlock,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Page", value: "page" },
        { text: "Views", value: "views" },
      ],
    }
  },
  computed: {
    items() {
      return this.data?.map(item => ({
        page: item.dimension.pagePath,
        views: new Intl.NumberFormat("en-US").format(item.metric.pageviews),
      }))
    },
  },
  mounted() {
  }
};
</script>

<style scoped>
.container {
  max-height: 100%;
  overflow-y: scroll;
}

.chart-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
</style>