<template>
  <AppWidgetBlock
    v-bind="{config}"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="d-flex flex-grow-1">
      <div
        v-if="items.length > 1"
        class="side-bar"
      >
        <v-btn
          v-for="item in items"
          :key="item.label"
          block
          depressed
          text
          class="justify-space-between text-left"
          :class="{'v-btn--has-bg  primary--text': selectedType == item.value}"
          max-width="200"
          height="36"
          @click="selectedType = item.value"
        >
          <span class="label text-none">{{ item.label }}</span>
          <v-icon size="12" class="right">
            $chevron_down_thin
          </v-icon>
        </v-btn>
      </div>

      <v-divider
        v-if="items.length > 1"
        vertical
        class="mx-4 my-n4 divider"
      />

      <div class="chart-container">
        <span class="chart-title">User Traffic</span>
        <bar-chart
          class="mt-10"
          style="width: 100%"
          :height="180"
          :chart-data="chartdata"
          :options="options"
        />
      </div>
    </div>
  </AppWidgetBlock>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import BarChart from "@/sharedComponents/charts/BarChart";
import { uniq } from "lodash"

export default {
  name: "BarChartWidget",
  components: {
    AppWidgetBlock,
    BarChart
  },
  props: {
    config: {
      default: () => ({
        size: '2x1',
        name: 'Best Day'
      })
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      selectedType: "source",
      items: [
        {
          label: "Source",
          value: "source",
        },
        {
          label: "Medium",
          value: "medium",
        },
        {
          label: "Campaign",
          value: "campaign",
        },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            stacked: true,
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              callback: function(value) {
                return new Intl.NumberFormat().format(value);
              }
            },
            stacked: true,
          }],
        }
      }
    }
  },
  computed: {
    mediumLabel() {
      const allLabel = this.data.medium?.map(item => item.dimension.medium)
      return uniq(allLabel);
    },
    sourceLabel() {
      const allLabel = this.data.source?.map(item => item.dimension.source)
      return uniq(allLabel);
    },
    campaignLabel() {
      const allLabel = this.data.campaign?.map(item => item.dimension.campaign)
      return uniq(allLabel);
    },
    mediumData() {
      return this.mediumLabel.map((item, index) => {
        return {
          backgroundColor: this.getColor(index),
          label: item,
          barThickness: 15,
          datalabels: {
            display: false,
          },
          data: this.data.medium?.filter(medium => medium.dimension.medium == item)?.map(item => item.metric.sessions) ?? [],
        }
      })
    },
    sourceData() {
      return this.sourceLabel.map((item, index) => {
        return {
          backgroundColor: this.getColor(index),
          label: item,
          barThickness: 15,
          datalabels: {
            display: false,
          },
          data: this.data.source?.filter(source => source.dimension.source == item)?.map(item => item.metric.sessions) ?? [],
        }
      })
    },
    campaignData() {
      return this.campaignLabel.map((item, index) => {
        return {
          backgroundColor: this.getColor(index),
          label: item,
          barThickness: 15,
          datalabels: {
            display: false,
          },
          data: this.data.campaign?.filter(campaign => campaign.dimension.campaign == item)?.map(item => item.metric.sessions) ?? [],
        }
      })
    },
    allDateRange() {
      if (this.selectedType == "medium") {
        return uniq(this.data.medium?.map(item => item.dimension.date))
      }
      if (this.selectedType == "source") {
        return uniq(this.data.source?.map(item => item.dimension.date))

      }
      if (this.selectedType == "campaign") {
        return uniq(this.data.campaign?.map(item => item.dimension.date))
      }

      return this.data.source
    },
    chartdata() {
      return {
        labels: this.allDateRange?.map(item => {
          const year = item.slice(0, 4);
          const month = parseInt(item.slice(4, 6)) - 1;
          const date = item.slice(6, 8);
          const d = new Date(year, month, date);
          return d.toLocaleString('default', { month: 'short' }) + " " + d.getDate();
        }) ?? [],
        datasets: this.datasets,
      }
    },
    datasets() {
      if (this.selectedType == "medium") {
        return this.mediumData
      }
      if (this.selectedType == "source") {
        return this.sourceData
      }
      if (this.selectedType == "campaign") {
        return this.campaignData
      }

      return this.mediumData
    },
  },
  watch: {
  },
  methods: {
    getColor(index) {
      const colorArr = [
        "#2B84EB",
        "#FD6868",
        "#EF9F31",
        "#7CD9D6",
        "#66CC66",
        "#6699CC",
        "#CC6699",
        "#FFCC99",
        "#CC66CC",
        "#881122",
        "#CCCCCC",
      ]
      if (index > colorArr.length - 1) {
        return colorArr[colorArr.length - 1]
      }
      return colorArr[index]
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  width: 220px;
}

.label {
  max-width: 150px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0 !important;
}

.right {
  transform: rotate(-90deg);
}

.divider {
  max-height: calc(100% + 32px) !important;
  min-height: calc(100% + 32px) !important;
}

.chart-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.chart-container {
  width: 100%;
}
</style>