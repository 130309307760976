<template>
  <app-widget-block
    v-bind="{ config }"
    class="container"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      class="flex-grow-1 flex-shrink-0"
    >
      <template #top>
        <span class="chart-title">Top Day of Week</span>
      </template>
      <template slot="body.append">
        <tr>
          <th>Total</th>
          <th>{{ totalSessions }}</th>
          <th>{{ totalTimeOnPage }}</th>
          <th>{{ totalBounceRate }}</th>
        </tr>
      </template>
    </v-data-table>
  </app-widget-block>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";

export default {
  name: "TopDayOfWeekWidget",
  components: {
    AppWidgetBlock,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Days", value: "page" },
        { text: "Sessions", value: "sessions" },
        { text: "Time on Page", value: "time_on_page" },
        { text: "Bounce rate", value: "bounce_rate" },
      ],
    }
  },
  computed: {
    totalSessions() {
      const total = this.data.reduce((partialSum, a) => partialSum + parseInt(a.metric.sessions), 0);
      return new Intl.NumberFormat("en-US").format(total)
    },
    totalTimeOnPage() {
      const totalTimeOnPage = this.data.reduce((partialSum, a) => partialSum + parseFloat(a.metric.avgTimeOnPage), 0) / Object.keys(this.data).length;
      return parseFloat(totalTimeOnPage).toFixed(2) + " sec";
    },
    totalBounceRate() {
      const totalBounceRate = this.data.reduce((partialSum, a) => partialSum + parseFloat(a.metric.bounceRate), 0) / Object.keys(this.data).length;
      return parseFloat(totalBounceRate).toFixed(2) + "%";
    },
    items() {
      const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
      return this.data?.map(item => {
        const year = item.dimension.date.slice(0, 4);
        const month = parseInt(item.dimension.date.slice(4, 6)) - 1;
        const date = item.dimension.date.slice(6, 8);
        const d = new Date(year, month, date).getDay();

        const sessions = item.metric?.sessions;
        const timeOnPage = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(item.metric.avgTimeOnPage)

        return {
          page: weekday[d],
          sessions: sessions ? new Intl.NumberFormat().format(sessions) : 0,
          "time_on_page": timeOnPage + " sec",
          "bounce_rate": parseFloat(item.metric.bounceRate).toFixed(2) + "%",
        }
      })
    },
  },
  mounted() {
  }
};
</script>

<style scoped>
.container {
  max-height: 100%;
  overflow-y: scroll;
}

.chart-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
</style>