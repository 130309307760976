<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/icons/google_analytics-horizontal.svg"
          alt="google analytic icon"
          width="400"
        >
      </template>

      <template #subheader>
        <div class="d-flex">
          <CustomDateRangePicker
            v-model="dateRanges"
            hide-details
            class="ml-auto"
            :max-date="new Date()"
          />
        </div>
      </template>
    </PageHeader>

    <v-container
      fluid
      class="mt-4"
      :style="{
        height: dynamic_height ? dynamic_height + 'px' : 'auto',
      }"
    >
      <v-row class="grid-stack grid-stack-4" style="z-index: 7">
        <div
          v-for="report_widget in report_widgets"
          :id="'report-widget-' + report_widget.id"
          :key="'report-widget-' + report_widget.id"
          :class="{
            'grid-stack-item': true,
            'ui-draggable-disabled': true,
            'ui-resizable-disabled': true,
          }"
          :gs-x="report_widget.xPosition"
          :gs-y="report_widget.yPosition"
          :gs-w="report_widget.width"
          :gs-h="report_widget.height"
        >
          <div class="grid-stack-item-content">
            <component
              :is="report_widget.renderComponentName"
              class="item-component"
              :config="report_widget"
              :data="report_widget.data"
              vertical
            />
          </div>
        </div>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CountWidget from "@/sharedComponents/widgets/CountWidget";
import LineChartWidget from "@/views/Plugins/Google/Widgets/LineChartWidget";
import PieChartWidget from "@/views/Plugins/Google/Widgets/PieChartWidget";
import BarChartWidget from "@/views/Plugins/Google/Widgets/BarChartWidget";
import USAHeatMapWidget from "@/views/Plugins/Google/Widgets/USAHeatMapWidget";
import CalendarHeatmapWidget from "@/views/Plugins/Google/Widgets/CalendarHeatmapWidget";
import TopPageViewWidget from "@/views/Plugins/Google/Widgets/TopPageViewWidget";
import TopDayOfWeekWidget from "@/views/Plugins/Google/Widgets/TopDayOfWeekWidget";
import CustomDateRangePicker from "@/sharedComponents/CustomDateRangePicker";

import dayjs from "dayjs";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";

const systemDateFormat = "YYYY-MM-DD";

export default {
  name: "GoogleAnalyticsView",
  metaInfo: {
    title: "Google Analytics",
  },
  components: {
    PageHeader,
    CountWidget,
    LineChartWidget,
    PieChartWidget,
    BarChartWidget,
    USAHeatMapWidget,
    CalendarHeatmapWidget,
    TopPageViewWidget,
    TopDayOfWeekWidget,
    CustomDateRangePicker,
  },
  props: {},
  data() {
    return {
      dateRanges: {
        start: new Date().setDate(new Date().getDate() -6),
        end: new Date(),
      },
      grid: null,
      grid_config: {
        alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
        resizable: true,
        column: 10,
        cellHeight: "160px", // minimum size when height of block equals 1. Should be high as much as possible, small value (1px) may cause performance issues
        acceptWidgets: false, // Disable dragging outside widgets
      },
      dynamic_height: 0,
      report_widgets: [
        {
          id: 1,
          xPosition: 0,
          yPosition: 0,
          widget_id: 1,
          name: "Total Visitors Count",
          height: 1,
          width: 2,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "CountWidget",
          account_config: null,
          data: {},
          component: "TotalVisitorsCountWidget",
        },
        {
          id: 2,
          xPosition: 2,
          yPosition: 0,
          widget_id: 2,
          name: "Total Sessions Count",
          height: 1,
          width: 2,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "CountWidget",
          account_config: null,
          update_key: "73ae8b077175cb23ed5a8c30e74b2064",
          data: {
          },
          component: "TotalVisitorsCountWidget",
          configuration: {
            color: "#5aa43b",
          },
        },
        {
          id: 3,
          xPosition: 4,
          yPosition: 0,
          widget_id: 3,
          name: "Avg Time On Site",
          height: 1,
          width: 2,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "CountWidget",
          account_config: null,
          update_key: "73ae8b077175cb23ed5a8c30e74b2064",
          data: {
          },
          component: "TotalVisitorsCountWidget",
          configuration: {
            color: "#ff5252",
          },
        },
        {
          id: 4,
          xPosition: 6,
          yPosition: 0,
          widget_id: 4,
          name: "New Users",
          height: 1,
          width: 2,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "CountWidget",
          account_config: null,
          update_key: "73ae8b077175cb23ed5a8c30e74b2064",
          data: {
          },
          component: "TotalVisitorsCountWidget",
          configuration: {
            color: "#9d52ff",
          },
        },
        {
          id: 10,
          widget_id: 10,
          xPosition: 8,
          yPosition: 1,
          height: 2,
          width: 3,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "CalendarHeatmapWidget",
          data: [],
        },
        {
          id: 9,
          widget_id: 9,
          xPosition: 0,
          yPosition: 5,
          height: 2,
          width: 5,
          data: [],
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "USAHeatMapWidget",
        },
        {
          id: 8,
          widget_id: 8,
          xPosition: 4,
          yPosition: 3,
          height: 2,
          width: 7,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "BarChartWidget",
          data: {},
        },
        {
          id: 7,
          widget_id: 7,
          xPosition: 0,
          yPosition: 3,
          height: 2,
          width: 3,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "PieChartWidget",
          data: [],
        },
        {
          id: 11,
          widget_id: 11,
          xPosition: 6,
          yPosition: 5,
          height: 2,
          width: 5,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "TopPageViewWidget",
          data: [],
        },
        {
          id: 6,
          widget_id: 6,
          xPosition: 0,
          yPosition: 1,
          height: 2,
          width: 7,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "LineChartWidget",
          data: {},
        },
        {
          id: 13,
          widget_id: 13,
          xPosition: 0,
          yPosition: 7,
          height: 3,
          width: 5,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "TopDayOfWeekWidget",
          data: [],
        },
        {
          id: 14,
          xPosition: 8,
          yPosition: 0,
          widget_id: 14,
          name: "Sessions Per User",
          height: 1,
          width: 2,
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "CountWidget",
          account_config: null,
          data: {
          },
          component: "TotalVisitorsCountWidget",
          configuration: {
            color: "#d3813a",
          },
        },
      ],
    };
  },
  computed: {
  },
  watch: {
    dateRanges: {
      deep: true,
      handler() {
        this.getAnalyticsData();
      }
    }
  },
  mounted() {
    this.init_grid();
    this.getAnalyticsData();
  },
  methods: {
    init_grid() {
      this.grid = GridStack.init(this.grid_config);
      this.grid.enableMove(false, false);
      this.grid.enableResize(false, false);
      window.grid = this.grid;

      setTimeout(this.calculateDynamicHeight.bind(this), 500);
    },
    calculateDynamicHeight() {
      // find lowest widget
      const domWidgets = document.querySelectorAll(".grid-stack-item");
      let bottomHeight = 0;
      for (const domWidget of domWidgets) {
        const bottomOfWidget = domWidget.offsetTop + domWidget.offsetHeight;
        if (bottomOfWidget > bottomHeight) {
          bottomHeight = bottomOfWidget;
        }
      }

      this.dynamic_height = bottomHeight > 0 ? bottomHeight + 50 : 0;
    },
    async getAnalyticsData() {
      const resp = await this.$rest.plugins.googleAnalytics.getReportData({
        types: [
          "overTime",
          "timeOfDay",
          "sessionByDevice",
          "userTraffic",
          "sessionByState",
          "pageVisit",
          "topDayOfWeek",
          "widgets",
        ],
        startDate: this.dateRanges?.start ? dayjs(this.dateRanges.start).format(systemDateFormat) : null,
        endDate: this.dateRanges?.end ? dayjs(this.dateRanges.end).format(systemDateFormat) : null,
      });

      const totalVisitorsUser = resp.data.widgets?.totalVisitors?.[0]?.metric?.users;
      this.report_widgets.splice(0, 1, {
        ...this.report_widgets[0],
        data: {
          count: totalVisitorsUser ? new Intl.NumberFormat().format(totalVisitorsUser) : 0,
        }
      })

      const totalSessions = resp.data.widgets?.totalSessions?.[0]?.metric?.sessions
      this.report_widgets.splice(1, 1, {
        ...this.report_widgets[1],
        data: {
          count: totalSessions ? new Intl.NumberFormat().format(totalSessions) : 0,
        }
      })

      const avgTimeOnSite = resp.data.widgets?.avgTimeOnSite?.[0]?.metric?.avgSessionDuration;
      this.report_widgets.splice(2, 1, {
        ...this.report_widgets[2],
        data: {
          count: avgTimeOnSite ? Number.parseFloat(avgTimeOnSite).toFixed(1) + " sec" : 0,
        }
      })

      const totalNewUsers = resp.data.widgets?.totalNewUsers?.[0]?.metric?.newUsers
      this.report_widgets.splice(3, 1, {
        ...this.report_widgets[3],
        data: {
          count: totalNewUsers ? new Intl.NumberFormat().format(totalNewUsers) : 0,
        }
      })
      this.report_widgets.splice(4, 1, {
        ...this.report_widgets[4],
        data: resp.data.timeOfDay.sessions ?? []
      })
      this.report_widgets[5].data = resp.data.sessionByState.sessions ?? {};
      this.report_widgets[6].data = resp.data.userTraffic;
      this.report_widgets[7].data = resp.data.sessionByDevice?.sessions ?? [];
      this.report_widgets[8].data = resp.data.pageVisit?.pageViews ?? [];
      this.report_widgets.splice(9, 1, {
        ...this.report_widgets[9],
        data: {
          overTime: resp.data.overTime,
          total: resp.data.widgets,
        },
      })
      this.report_widgets[10].data = resp.data.topDayOfWeek?.[0];
      const sessionsPerUser = resp.data.widgets?.sessionsPerUser?.[0]?.metric?.sessionsPerUser
      this.report_widgets.splice(11, 1, {
        ...this.report_widgets[11],
        data: {
          count: sessionsPerUser ? Number.parseFloat(sessionsPerUser).toFixed(1) : 0,
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../../../node_modules/gridstack/dist/gridstack-extra.css");

.item-component {
  height: 100%;
}
</style>
