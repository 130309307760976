<template>
  <AppStatBlock
    v-bind="{ config }"
    class="stat-block-info stat-block-1"
    :class="{'': !hasBackgroundColor}"
    :style="dynamicStyling"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <template v-if="config.nameIconTooltip" #stat-name-icon>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="white"
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ config.nameIconTooltip }}</span>
      </v-tooltip>
    </template>
    <template #stat-number>
      <div class="count">
        {{ data.count ? data.count.toLocaleString() : 0 }}
      </div>
    </template>
  </AppStatBlock>
</template>

<script>
import AppStatBlock from "@/views/Dashboards/Widgets/components/AppStatBlock";
import {dynamicBackground} from "@/mixins/dynamicBackground";

export default {
  name: "CountWidget",
  components: {
    AppStatBlock,
  },
  mixins: [dynamicBackground],
  props: {
    config: {
      default: () => ({
        size: "1x1",
        name: "Count stat",
      }),
    },
    data: {
      default: () => ({
        count: 0
      }),
    }
  },
};
</script>

<style lang="scss" scoped>
.stat-block-info {
  background-image: var(--bgImage);
  border-radius: 4px;
}

.count {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
