<template>
  <div data-app class="custom-date-picker">
    <div class="field__subheader">
      {{ header }}
    </div>
    <validation-observer ref="textInput">
      <v-menu
        v-model="showDatePicker"
        :close-on-content-click="false"
        :close-on-click="true"
        transition="scale-transition"
        class="date-picker"
        min-width="230"
      >
        <template #activator="{}">
          <validation-provider
            v-slot="{ errors }"
            :rules="{
              required
            }"
            mode="eager"
          >
            <v-text-field
              :value="dateAndTime"
              :class="{'text-field-disabled': disabled, 'mt-4': header}"
              dense
              readonly
              outlined
              clearable
              :error-messages="errors"
              height="30px"
              :placeholder="placeholder"
              :hide-details="hideDetails"
              @click:clear="clear_date"
              @click-on-append-icon="showDatePicker = true"
              @click="showDatePicker = true"
            >
              <template #append>
                <v-btn
                  class="date-picker__button"
                  small
                  icon
                  @click="showDatePicker = true"
                >
                  <v-icon size="18" color="#66788e">
                    mdi-calendar-range
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </validation-provider>
        </template>

        <date-picker
          v-if="showDatePicker"
          v-model="dateRanges"
          is-range
          :masks="{ input: format }"
          :min-date="minDate"
          :max-date="maxDate"
          :columns="2"
          :step="1"
          @dayclick="($event) => $emit('day-click', $event)"
          @input="onInput"
        />
      </v-menu>
    </validation-observer>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: "CustomDateRangePicker",
  components: { DatePicker },
  mixins: [datesMixin],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    header: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => "Choose A Date Range",
      type: String,
    },
    format: {
      type: String,
      default: () => 'MM/DD/YYYY',
    },
    outer_date: {
      default: () => null,
    },
    disabled: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    minDate: {
      type: Date,
      default: () => null,
    },
    maxDate: {
      type: Date,
      default: () => null,
    },
    value: {
      type: Object,
      default: () => null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDatePicker: false,
      dateRanges: null,
    };
  },
  computed: {
    dateAndTime() {
      if (this.dateRanges?.start && this.dateRanges?.end) {
        return this.getDate(this.dateRanges.start) + ' - ' + this.getDate(this.dateRanges.end);
      }

      return '';
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (val.start && val.end) {
          this.$set(this, 'dateRanges', this.value);
        }
      },
    },
  },
  created() {
    if (this.value?.start && this.value?.end) {
      this.$set(this, 'dateRanges', this.value);
    }
  },
  methods: {
    setDates(startDate, endDate) {
      this.$set(this, 'dateRanges', {
        start: startDate,
        end: endDate,
      });
    },
    onInput(value) {
      this.$emit('input', {...value});
      this.$emit('update:dates', {...value});
      this.showDatePicker = false;
    },
    clear_date() {
      this.$emit('input', null);
      this.$emit('update:dates', null);
    }
  },
};
</script>

<style lang="scss">
.item-details-card__avatar {
  background-size: cover;
  width: 20px !important;
  height: 20px !important;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}
</style>
