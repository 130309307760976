import { render, staticRenderFns } from "./CountWidget.vue?vue&type=template&id=2881f862&scoped=true"
import script from "./CountWidget.vue?vue&type=script&lang=js"
export * from "./CountWidget.vue?vue&type=script&lang=js"
import style0 from "./CountWidget.vue?vue&type=style&index=0&id=2881f862&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2881f862",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
