<template>
  <app-widget-block
    v-bind="{ config }"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <span class="chart-title">Time of Day</span>
    <div class="d-flex">
      <div id="calendar-heatmap" class="flow-grow-1" />
      <div class="ml-1 time-labels">
        <div v-for="time in timeRange" :key="time" class="label">
          {{ time }}
        </div>
      </div>
    </div>
  </app-widget-block>
</template>

<script>
import CalHeatMap from "@/sharedComponents/heatmap/cal-heatmap.js";
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import { isEmpty } from "lodash";

export default {
  name: "CalendarHeatmapWidget",
  components: {
    AppWidgetBlock,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      firstTimeRendered: true,
      timeRange: [
        "12am",
        "2am",
        "4am",
        "6am",
        "8am",
        "10am",
        "12pm",
        "2pm",
        "4pm",
        "6pm",
        "8pm",
        "10pm",
      ],
      cal: new CalHeatMap(),
    }
  },
  computed: {
    timeData() {
      const timeData = {}
      this.data.forEach((item) => {
        const d = this.getLastDate(item.dimension.dayOfWeekName);
        d.setHours(item.dimension.hour);

        const timeStampSecond = d.getTime() / 1000;

        timeData[timeStampSecond] =  parseInt(item.metric.sessions);
      })
      
      return timeData;
    },
    startDate() {
      // To always start the date of calendar on Sunday
      return this.getLastDate("Sunday");
    },
  },
  watch: {
    timeData: {
      deep: true,
      handler(val) {
        let max = 0;
        if (!isEmpty(val)) {
          const arr = Object.values(val);
          max = Math.max(...arr);
        }
        if (max >= 0) {
          if (this.firstTimeRendered) {
            this.reRenderCal(max);
            this.firstTimeRendered = false;
          } else {
            this.cal.update(val, true, 1);
          }
        }
      }
    },
  },
  mounted() {
  },
  methods: {
    getLastDate(d) {
      // These are only a placeholder date to pass in the calendar component
      // They don't have any specific meaning beside start on Sunday and end at Monday
      // TODO: Maybe refactor this later
      const dateRage = {
        "Sunday": "November 6, 2022",
        "Monday": "November 7, 2022",
        "Tuesday": "November 8, 2022",
        "Wednesday": "November 9, 2022",
        "Thursday": "November 10, 2022",
        "Friday": "November 11, 2022",
        "Saturday": "November 12, 2022",
      }
      return new Date(dateRage[d])
    },
    reRenderCal(max) {
      const windowWidth = document.documentElement?.clientWidth;

      this.cal.init({
        itemSelector: "#calendar-heatmap",
        range: 7,
        domain: "day",
        subDomain: "hour",
        rowLimit: 24,
        cellRadius: 2,
        cellXSize: windowWidth ? windowWidth / 50 : 25,
        cellYSize: 7,
        start: this.startDate,
        domainGutter: 1,
        data: this.timeData,
        legend: [0, max / 3, (2 * max) / 3, max],
        legendColors: {
          min: "#e0d2fe",
          max: "#6e54a5",
          empty: "#e0d2fe",
        },
        domainLabelFormat(date) {
          const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
          const d = new Date(date).getDay();
          return weekday[d]
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/cal-heatmap.scss";
</style>

<style scoped>
.chart-title {
  font-size: 15px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 15px;
  margin-bottom: 8px;
}

.time-labels {
  font-size: 11px;
}

.label {
  line-height: 11px;
  margin-bottom: 7px;
}
</style>